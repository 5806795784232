//DONE
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { SeoService } from '../services/seo.service';

@Component({
  selector: 'app-info-page',
  template: `
    <h1 class="song-name">About</h1>
    <div class="container">
      <div class="transparent"></div>
      <p><em>Let me get this out of the way first: this page is not associated with the Longest Johns. It is just a programming excercise.</em></p>
      <p>This project started out with the humble question: <em>I'm sure the Longest Johns did a version of Fiddler's Green, but where was it?</em> I found it and decided to make a list of all songs from the livestreams. There are always people (often more than one) who comment with the timestamps, so it was a simple matter of copying those comments. The timestamps are a link, so I could use them to quickly navigate to a song when I wanted to learn or hear it.</p>
      <p>Then came the need to learn something new. I wanted to learn some programming languages and webdesign. The course I followed had a project that you could work on, but the topic was not interesting enough. Fortunately, the data from that project was very similar to my list of songs, so I needed very few adaptations.</p>
      <p>When I finished the course book, the website was almost functional. So I decided to finish it. I learned basic HTML and CSS to make it a little more unique than Bootstrap (all you webdesigners will get what I'm talking about) and now you can actually use it!</p>
      <p>Who would have thought a simple question could lead to this?</p>
    </div>
    <div style="margin-top:6em; height:1px;"> </div>
  `})
export class InfoPageComponent {

  constructor(private seo: SeoService, private titleService: Title) {}

  ngOnInit() {
    this.seo.generateTags({
			title: "About",
      description: "Find out why this website exists",
      slug: "about"
    })
    this.titleService.setTitle( "About" );
  }
}
