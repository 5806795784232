import { Component, OnInit, ViewChild } from '@angular/core';
import { Song } from '../model/song';
import { Subscription } from 'rxjs';
import { SongService } from '../services/song.service';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { SongDetailComponent } from '../song-detail/song-detail.component';
import { SeoService } from '../services/seo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-random',
  templateUrl: './random.component.html', 
  styleUrls: ['./random.component.css']
})
export class RandomComponent implements OnInit {

  currentSong:Song;
  songs:Song[];
  songSubscription:Subscription;
  sanitized:SafeResourceUrl;
  shouldRefine:boolean = false;
  
  @ViewChild(SongDetailComponent) child:SongDetailComponent;

  constructor(private router:Router, private songService:SongService, private sanitizer:DomSanitizer, private seo: SeoService, private titleService: Title) { }

  ngOnInit() {
    this.songSubscription = this.songService.getSongs()
			.subscribe(
				songData => this.songs = songData as Song[],
				err => console.log(err),
				() => console.log('Retrieving songs completed')
      )
      
      this.seo.generateTags({
        title: "Longest Johns Random Song Picker",
        description: "Get a random song from the Longest Johns",
        slug: "randomizer"
      })
      this.titleService.setTitle( "Longest Johns Random Song" );
  }

  randomize(){
    this.currentSong = this.songs[Math.floor(Math.random()*(this.songs.length-1))];
    this.child.sanitize(this.currentSong);
  }

  showAllVersions(){
		var route:string = '/allversions/' + this.currentSong.name;
		console.log(route);
		this.router.navigateByUrl(route)
	}

  ngOnDestroy()
	{
		this.songSubscription.unsubscribe();
	}

}
