//DONE
import { Component } from '@angular/core';



@Component({
  selector: 'app-root',
  template: `
    <header>
      <ul>
        <li class="navi"><a routerLink="">Home</a></li>
        <li class="navi"><a routerLink="/about">About</a></li>
        <li class="navi"><a routerLink="/random">Random</a></li>
        <li class="navi"><a routerLink="/searcher">Searcher</a></li>
      </ul>
    </header> 

    <router-outlet></router-outlet>

    <div class="background-image"></div>

		<footer>
			<p>The Song Searcher is <em>not</em> associated with the Longest Johns. Copyright &copy; 2018, White Falcon</p>
		</footer>

		<div class="background-parent">
			<div class="background-container">
				<img class="background" src='./assets/images/background.jpg' alt=""/>
			</div>
		</div>	
  `
})
export class AppComponent {
  
}
