//IN PROGRESS
import { BrowserModule, Title, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppModule } from './app.module';

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'this-is-an-app-id',}),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AppModule,
    BrowserTransferStateModule
    ],
  providers: [
    Title,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
