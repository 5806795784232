//DONE
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { SeoService } from '../services/seo.service';

@Component({
  selector: 'app-not-found',
  template: 
  `
	<div class="container">
		<h3>We've run into some trouble finding your page...</h3>
	</div>
	<div class="not-found-parent">
		<div class="welcome-container">
			<img class="welcome not-found" src='./assets/images/notFound.png' alt="404, page not found"/>
		</div>
	</div>
	`
})
export class NotFoundComponent {
	constructor (private seo: SeoService, private titleService: Title) {}

	ngOnInit(){
		this.seo.generateTags({
			title: "Page Not Found",
			description: "We ran aground trying to find this page",
			slug: "not found"
		})
		this.titleService.setTitle( "Page Not Found" );
	}
}
