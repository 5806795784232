//IN PROGRESS
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DatePipe, CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { AllVersions } from './all-versions/all-versions.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HomePageComponent } from './home-page/home-page.component';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { RandomComponent } from './random/random.component';
import { DetailModule } from './song-detail/detail/detail.module';

@NgModule({
  declarations: [
    AppComponent,
    InfoPageComponent,
    AllVersions,
    NotFoundComponent,
    HomePageComponent,
    RandomComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    TransferHttpCacheModule,
    NgtUniversalModule,
    DetailModule
  ],
  providers: [
    Title,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
