//DONE
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { AllVersions } from './all-versions/all-versions.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RandomComponent } from './random/random.component';

const routes: Routes = [
	{path: '', component: HomePageComponent},
	{path: 'about', component: InfoPageComponent},
	{path: 'searcher', loadChildren: './song-searcher/song-searcher.module#SongSearcherModule'},
	{path: 'allversions/:name', component: AllVersions},
	{path: 'random', component: RandomComponent},
	{path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
