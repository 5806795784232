//DONE
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { SeoService } from '../services/seo.service';

@Component({
  selector: 'app-home-page',
  template: `
    <h1 class="song-name">Ahoy!</h1>
    <div class="welcome-parent">
      <div class="welcome-container">
        <a (click)="toSearcher()"><img class="welcome" src='./assets/images/welcome.png' alt="Welcome to the Longest Johns Song Searcher"/></a>
      </div>
    </div>
  `,
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent {

  constructor(private router:Router, private seo: SeoService, private titleService: Title) {}

  ngOnInit() {
    this.seo.generateTags({
			title: "Home Page",
			description: "Welcome to the Longest Johns Song Searcher"
    })
    this.titleService.setTitle( "Home Page" );
  }

	toSearcher(){
		var route:string = '/searcher';
		this.router.navigateByUrl(route)
	}

}
