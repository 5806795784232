//DONE
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { Song } from '../model/song';
import { Wiki } from '../model/wiki';

const CACHE_SIZE = 1;
const API_ENDPOINT = './assets/json/songs.json';
const API_ENDPOINT2 = './assets/json/wiki.json';

@Injectable({
  providedIn: 'root'
})

export class SongService {

  constructor(private http: HttpClient) { }
  private songs:Observable<Song[]>;
  private wikis:Observable<Wiki[]>;

  getWikis(){
    if(!this.wikis) {
      this.wikis = this.requestWikis().pipe(
        shareReplay(CACHE_SIZE)
      );
    }
    return this.wikis;
  }

  getSongs(){
     if (!this.songs) {
      this.songs = this.requestSongs().pipe(
        shareReplay(CACHE_SIZE)
      );
    }
     return this.songs;
  }

  private requestSongs() {
    return this.http.get<Song[]>(API_ENDPOINT);
  }
  
  private requestWikis() {
    return this.http.get<Wiki[]>(API_ENDPOINT2);
  }
}
