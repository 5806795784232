//DONE
export class Song {
    constructor
	(
		public name: string,
		public source: string,
		public embedSource: string,
		public date: string,
		public sourceName: string,
  )
	{}
}
