//TO START
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Song } from '../model/song';
import { SongService } from '../services/song.service';
import { SeoService } from '../services/seo.service';

import { Subscription } from 'rxjs';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';

@Component({
	selector: 'app-all-versions',
	providers: [SongService],
	templateUrl: './all-versions.component.html',
	styleUrls: ['./all-versions.component.css']
})
export class AllVersions implements OnInit {

	songs: Song[] = [];
	name: string = "unknown";
	foundSongs: Song[] = [];
	showHideTxt: string = "show";
	showingLyrics: boolean[] = [];
	songSubscription: Subscription;
	wikiSubscription: Subscription;
	wikiLink:string;
	counter: number = 0;
	counter2: number;
	sanitized: SafeResourceUrl[] = [];

	constructor(private songService: SongService, private route: ActivatedRoute, private seo: SeoService, private titleService: Title, private sanitizer: DomSanitizer) {
	}

	ngOnInit() {
		this.songSubscription = this.songService.getSongs()
			.subscribe(
				songData => this.songs = songData as Song[],
				err => console.log(err),
				() => this.foundSongs = this.songs.filter((value) => {
					if (this.name.toLowerCase() == value.name.toLowerCase()) {
						this.showingLyrics.push(false);
						this.sanitized.push(this.sanitizer.bypassSecurityTrustResourceUrl(value.embedSource));
						return value;
					}
					else {
						return null;
					}

				})
			)

		this.wikiSubscription = this.songService.getWikis()
			.subscribe(
				wikiData => {
					wikiData.forEach(wiki => {
						if(wiki.name.toLowerCase() === this.name.toLowerCase()){
							this.wikiLink = wiki.link;
							return;
						}
					})
				}
				)

		this.name = this.route.snapshot.paramMap.get('name');

		console.log(this.name.toLowerCase());

		this.seo.generateTags({
			title: this.name,
			description: "All versions of " + this.name,
			slug: "allversions/" + this.name
		})
		this.titleService.setTitle(this.name);



	}

	ngOnDestroy() {
		this.songSubscription.unsubscribe();
	}

}
