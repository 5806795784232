//DONE
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Song } from '../model/song';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-song-detail',
  templateUrl: './song-detail.component.html',
  styleUrls: ['./song-detail.component.css']
})
export class SongDetailComponent {

  sanitized:SafeResourceUrl;
  submitSubscription: Subscription;
  confirmed:boolean = false;

  constructor(private sanitizer: DomSanitizer) {
  }
  @Input() song:Song;
  ngOnInit(){
    this.sanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.song.embedSource);
  }

  ngOnDestroy() {
		if (this.submitSubscription) {
			this.submitSubscription.unsubscribe();
		}
	}

  sanitize(song:Song){
    this.sanitized = this.sanitizer.bypassSecurityTrustResourceUrl(song.embedSource);
  }
}
